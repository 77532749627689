<template>
  <div class="preview">
    <!--  HEADER  -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ texts.previewPage.title }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" @update:activeTab="updateActiveTab" ref="achievementTabs">
      <CTab :title="texts.previewPage.achievementTabTitle">
        <AchievementDetails v-if="isAchievement" @setAchievementStatus="setAchievementStatus" />
      </CTab>
      <CTab :title="texts.previewPage.entrantsTab">
        <EntrantsDetails v-if="isEntrants" :entityId="$route.params.id"/>
      </CTab>
      <CTab :title="texts.previewPage.awardsTab" v-if="achievementStatus === 'Finished' || 'Active'">
        <AwardsDetails v-if="isAwards" :entityType="'Achievement'" />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import AchievementDetails from '@/views/ziqni/achievements/AchievementDetails';
import EntrantsDetails from '@/shared/components/supportModels/entrants/EntityEntrants';
import AwardsDetails from './AwardsDetails';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { achievements } from '@/config/descriptions/achievements';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';

export default {
  name: 'PreviewAchievement',
  data() {
    return {
      descriptions: {
        ...achievements.list.preview
      },
      texts: {
        ...achievementsTexts
      },
      isAchievement: false,
      isEntrants: false,
      isAwards: false,
      tabsMap: {
        0: 'isAchievement',
        1: 'isEntrants',
        2: 'isAwards',
      },
      achievementStatus: ''
    }
  },
  components: {
    AchievementDetails,
    EntrantsDetails,
    AwardsDetails,
    IconWithTooltip,
  },
  mounted() {
    this.isAchievement = true;
  },
  methods: {
    updateActiveTab(val) {
      switch (this.tabsMap[val]) {
        case 'isAchievement':
          this.isAchievement = true;
          break;
        case 'isEntrants':
          this.isEntrants = true;
          break;
        case 'isAwards':
          this.isAwards = true;
          break;
      }
    },
    setAchievementStatus(status) {
      this.achievementStatus = status;
    }
  }
}
</script>

<style lang="scss">
.preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>