<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard class="zq--wizard-card">
          <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
            <div class="d-flex justify-content-between">
              <strong class="title">{{ 'Mission Graph' }}</strong>
              <CLink class="card-header-action btn-minimize">
                <ClCardArrow :is-collapsed="basicIsCollapsed" />
              </CLink>
            </div>
          </CCardHeader>
          <CCollapse :show="basicIsCollapsed" :duration="400">
            <CCardBody>
              <div
                  ref="graphContainer"
                  class="graph-container"
                  @wheel="onMouseWheel"
                  @mousedown="onDragStart"
                  @mousemove="onDragging"
                  @mouseup="onDragEnd"
              ></div>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <Modal
      :modalShow="isShowModal"
      :messageGeneral="messageGeneral"
      :title="modalTitle"
      @doFunction="goToAchievement"
      v-on:toggle-modal="closeModal"
      @closeModal="closeModal"
      :isSuccessButton="isModalSuccessButton"
      :successBtnLabel="'Go to Achievement'"
    />
  </div>
</template>

<script>
import * as d3 from 'd3';
import ClCardArrow from '@/shared/UI/ClCardArrow.vue';
import { mapActions } from 'vuex';
import Modal from '@/shared/UI/Modal.vue';
import { dateFormate } from '@/utils/dateFormate';

export default {
  name: 'MissionsGraph',
  components: { Modal, ClCardArrow },
  props: {
    graphData: Object,
    missionsData: Array
  },
  data() {
    return {
      basicIsCollapsed: true,
      iconsUri: {},
      isShowModal: false,
      isModalSuccessButton: true,
      modalTitle: '',
      messageGeneral: '',
      edgeTypeColors: {
        'MUST': '#6FCF97',
        'SHOULD': 'rgb(238, 187, 0)',
        'MUST-NOT': '#EB5757'
      },
      entityId: null,
      scale: 1,
      isDragging: false,
      dragStartCoords: { x: 0, y: 0 },
    };
  },
  async mounted() {
    await this.loadIcons();
    await this.createGraph();
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    async createGraph() {
      const graphData = this.graphData;
      const nodes = graphData.nodes;
      const edges = graphData.graphs[0].edges;

      const cardElement = this.$el.parentElement;

      const width = cardElement.clientWidth - 44;
      const height = 500;
      const nodeRadius = 20;

      // Forming a hierarchy for d3.tree()
      const rootNodeId = edges.find(edge => edge.graphEdgeType === 'ROOT').tailEntityId;
      const root = d3.hierarchy({ children: this.buildTree(nodes, edges, rootNodeId) });

      const treeLayout = d3.tree()
          .size([height, width])
          .separation((a, b) => (a.parent === b.parent ? 1 : 1) / a.depth); // separation: function that determines the distance between nodes.

      // Applying parameters to treeLayout
      const treeData = treeLayout(root);
      const nodesData = treeData.descendants();

      const linksData = treeData.links().map(link => {
        const sourceEntityId = link.source.data.entityId || rootNodeId;

        const edge = edges.find(e => {
          const targetEntityId = link.target.data.entityId;
          return (
              (e.headEntityId === targetEntityId && e.tailEntityId === sourceEntityId) ||
              (e.headEntityId === sourceEntityId && e.tailEntityId === targetEntityId)
          );
        });

        const edgeType = edge ? edge.graphEdgeType : null;
        const edgeColor = this.getEdgeColor(edgeType);

        return {
          ...link,
          edgeColor,
        };
      });

      // Reducing the distance between nodes
      nodesData.forEach(d => {
        d.y = d.depth * 125; // Change the value as you wish
      });

      const svg = d3.select(this.$refs.graphContainer)
          .append('svg')
          .attr('width', width)
          .attr('height', height)
          .append('g')
          .attr('transform', `translate(50,0)`);

      const link = svg.selectAll('.link')
          .data(linksData)
          .enter()
          .append('line')
          .attr('class', 'link')
          .style('stroke', d => d.edgeColor)
          .style('stroke-dasharray', '2,2')
          .attr('x1', d => d.source.y)
          .attr('y1', d => d.source.x)
          .attr('x2', d => d.target.y)
          .attr('y2', d => d.target.x);

      const node = svg.selectAll('.node')
          .data(nodesData)
          .enter()
          .append('g')
          .attr('class', 'node')
          .attr('transform', d => `translate(${d.y},${d.x})`);

      node.append('circle')
          .attr('r', nodeRadius)
          .style('fill', d => {
            const icon = d.data.missionData && d.data.missionData.icon
                ? d.data.missionData.icon
                : null;

            return icon ? 'transparent' : 'lightgray';
          })
          .attr('clip-path', (d, i) => `url(#circle-clip-${i})`);

      const defs = svg.append('defs');

      // Create a mask
      defs.append('clipPath')
          .attr('id', 'circle-clip')
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', nodeRadius - 2);

      // Adding an image with a mask
      node.append('image')
          .attr('xlink:href', d => {
            if (d.depth === 0) {
              return this.iconsUri[rootNodeId];
            } else {
              return d.data.missionData && d.data.missionData.icon
                  ? d.data.missionData.icon
                  : '';
            }
          })
          .attr('x', -nodeRadius)
          .attr('y', -nodeRadius)
          .attr('width', nodeRadius * 2)
          .attr('height', nodeRadius * 2)
          .attr('clip-path', 'url(#circle-clip)')
          .style('fill', d => {
            const icon = d.data.missionData && d.data.missionData.icon
                ? d.data.missionData.icon
                : null;

            return icon ? null : 'lightgray';
          });

      node.append('text')
          .text(d => {
            if (d.depth === 0) {
              const rootMission = this.missionsData.find(item => item.id === rootNodeId);
              return rootMission.name;
            } else {
              return d.data.missionData && d.data.missionData.name ? d.data.missionData.name : ''
            }

          })
          .attr('text-anchor', 'middle')
          .attr('dy', -nodeRadius - 3);

      node.on('click', (event, d) => {
        const entityId = d.data.entityId ? d.data.entityId : rootNodeId;
        this.showModal(entityId);
      });
    },
    onMouseWheel(event) {
      const delta = event.deltaY;
      if (delta > 0) {
        this.scale /= 1.2;
      } else {
        this.scale *= 1.2;
      }
      this.updateGraph();

      event.preventDefault();
    },
    updateGraph() {
      const cardElement = this.$el.parentElement;

      const cardWidth = cardElement.clientWidth - 44;
      const cardHeight = 500;

      d3.selectAll('.node')
          .attr('transform', d => `translate(${d.y * this.scale},${d.x * this.scale})`);

      d3.selectAll('.link')
          .attr('x1', d => d.source.y * this.scale)
          .attr('y1', d => d.source.x * this.scale)
          .attr('x2', d => d.target.y * this.scale)
          .attr('y2', d => d.target.x * this.scale);

      d3.select(this.$refs.graphContainer)
          .select('svg')
          .attr('width', cardWidth)
          .attr('height', cardHeight);
    },
    onDragStart(event) {
      this.isDragging = true;
      this.dragStartCoords = { x: event.clientX, y: event.clientY };
      this.updateGraph();
    },
    onDragging(event) {
      if (this.isDragging) {
        const dx = (event.clientX - this.dragStartCoords.x) / this.scale;
        const dy = (event.clientY - this.dragStartCoords.y) / this.scale;
        this.dragStartCoords = { x: event.clientX, y: event.clientY };

        d3.selectAll('.node')
            .attr('transform', function(d) {
              return `translate(${(d.y += dx)},${(d.x += dy)})`;
            });

        d3.selectAll('.link')
            .attr('x1', d => d.source.y)
            .attr('y1', d => d.source.x)
            .attr('x2', d => d.target.y)
            .attr('y2', d => d.target.x);
      }
      this.updateGraph();
    },
    onDragEnd() {
      this.isDragging = false;
    },
    getEdgeColor(edgeType) {
      switch (edgeType) {
        case 'MUST':
          return '#6FCF97';
        case 'SHOULD':
          return 'rgb(238, 187, 0)';
        case 'MUST-NOT':
          return '#EB5757';
        default:
          return '#ccc';
      }
    },
    showModal(entityId) {
      this.isShowModal = true;
      this.entityId = entityId;

      const mission = this.missionsData.find(mission => mission.id === entityId);
      this.modalTitle = `Name: ${mission.name}`;

      const startDate = dateFormate(mission.scheduling.startDate).split(',')[0];
      const endDate = dateFormate(mission.scheduling.endDate).split(',')[0];

      this.messageGeneral = `
          Status: ${mission.status} <br>
          Start date: ${startDate} <br>
          End date: ${endDate} <br>
      `;
    },
    closeModal() {
      this.isShowModal = false;
      this.entityId = null;
      this.modalTitle = '';
      this.messageGeneral = '';
    },
    goToAchievement() {
      this.$router.push({
        name: 'PreviewAchievement',
        params: {
          id: this.entityId,
        }
      });
    },
    buildTree(nodes, edges, rootNodeId) {
      const buildTreeRecursive = (parentId = null) => {
        const children = edges
            .filter(edge => edge.headEntityId === parentId)
            .map(edge => {
              const childNode = nodes.find(node => node.entityId === edge.tailEntityId);
              const missionData = this.missionsData.find(mission => mission.id === childNode.entityId);

              return {
                ...childNode,
                missionData: { ...missionData, icon: this.iconsUri[missionData.id] },
                children: buildTreeRecursive(childNode.entityId),
              };
            });
        return children.length ? children : null;
      };

      return buildTreeRecursive(rootNodeId);
    },
    async loadIcons() {
      const iconUris = {};

      for (const mission of this.missionsData) {
        const iconId = mission.icon;
        if (iconId) {
          const file = await this.handleGetFileObjects_item({ idArray: [iconId] });
          if (file && file.length > 0) {
            iconUris[mission.id] = file[0].uri;
          } else {
            iconUris[mission.id] = null;
          }
        } else {
          iconUris[mission.id] = null;
        }
      }

      this.$set(this, 'iconsUri', iconUris);
    },
  },
};
</script>

<style scoped lang="scss">
.node text {
  user-select: none;
}
</style>
